<template>
  <div class="inside-page-content">
    <div class="paper">
      <div class="top-search">
        <top-filter
          :typeList="typeList"
          :sortConfig="sortConfig"
          @handleSearchParams="handleSearch"
        ></top-filter>
      </div>
      <div class="pro-list">
        <div class="item" v-for="(t, i) in dataList" :key="i">
          <div class="product">
            <img :src="t.pictureUrl" height="104" />
            <div class="info">
              <div class="title">
                <div class="type">{{t.activityName}}</div>
                <div class="icon">{{activityTypeDic[t.activityType]}}</div>
              </div>
              <div class="desc">
                <div class="desc-item">
                  <i class="el-icon-time"></i>
                  <label class="info-text">举办时间：</label>
                  <label class="info-text">{{t.hostTimeStart}} 至 {{t.hostTimeEnd}}</label>
                </div>
                <div class="desc-item">
                  <i class="el-icon-location-outline"></i>
                  <label class="info-text">活动地点：</label>
                  <label class="info-text">{{t.hostVenue}}</label>
                </div>
              </div>
            </div>
            <div class="more" @click="handleDetailClick(t.id)">查看详情</div>
          </div>
        </div>
        <div class="footer">
          <div class="left">共 {{total}} 条</div>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page.pageNum"
            layout="prev, pager, next"
            :page-size="8"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'activeList',
  components: {
    TopFilter
  },
  data() {
    return {
      activityTypeDic: { 1: "培训活动", 2: "路演活动", 3: "其他活动" },
      dataList: [],
      total: null,
      page: {
        pageNum: 1,
        pageSize: 8
      },
      queryInfo: {
        keywords: null,
        type: 1
      },
      current: 0,
      keyword: "",
      typeList: [
        {
          name: '活动类型',
          code: 'activityType',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '培训活动',
              val: 1,
            },
            {
              name: '路演活动',
              val: 2,
            },
            {
              name: '其他活动',
              val: 3,
            }
          ]
        },
      ],
      sortConfig: [
        {
          name: '发布时间',
          code: 1,
        },
      ]
    }
  },
  computed: {
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    getList() {
      let params = Object.assign(this.page, this.queryInfo)
      this.$api.technologyFinance.activityList(params).then((res) => {
        let data = res.data;
        this.dataList = data.rows
        this.total = data.total
      })
    },
    handleSearch(params) {
      // 发起请求
      this.queryInfo = params
      // this.queryInfo.keywords = params.keywords
      this.getList();
    },
    handleDetailClick(id) {
      this.$router.push({ path: "/technologyFinance/activeDetail", query: { id: id } });
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-search {
  background: #fff;
  padding: 0 40px;
}
.pro-list {
  background: #fff;
  margin-top: 20px;
  padding: 30px;
  .item {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0;
    .product {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      height: 150px;
      border: 1px solid #ececec;
      align-items: center;
      .info {
        flex: 0.8;
        display: flex;
        flex-direction: column;
        .title {
          display: flex;
          flex-direction: row;
          .type {
            font-weight: 700;
            padding: 10px 20px;
          }
          .name {
            font-size: 14px;
            font-weight: 200;
            padding: 10px 20px;
          }
          .icon {
            font-size: 14px;
            color: blue;
            padding: 10px 20px;
          }
        }

        .desc {
          display: flex;
          flex-direction: column;
          padding: 10px 20px;
          justify-content: space-around;
          .desc-item {
            display: flex;
            flex-direction: row;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .info-text {
              font-size: 14px;
              font-weight: 200;
            }
          }
        }
      }
      .more {
        display: inline-block;
        background-color: #0046c0;
        color: #fff;
        padding: 8px 15px;
        border-radius: 6px;
        margin: 10px 20px 20px;
        cursor: pointer;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #0046c0;
    }
  }
}
</style>
